<template>
  <v-card>
    <v-card-title>テンプレート(新規)</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div
          v-for="msg of apierror.messages"
          :key="msg"
        >
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
    </template>
    <v-spacer></v-spacer>
    <v-form class="ma-6">
      <v-row align="center">
        <v-col
          cols="12"
          md="2"
        >
          <label for="reserve_name">テンプレート名<span style="color: red">*</span></label>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="$v.Form.tmpl_name.$model"
            outlined
            dense
            placeholder="テンプレート名"
            :error-messages="nameErrors"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col
          cols="12"
          md="2"
        >
          <span>契約科目内容</span><span style="color: red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="7"
        >
          <v-sheet
            width="100%"
            min-height="240"
            outlined
            class="d-flex align-start flex-column"
          >
            <div class="d-flex justify-end px-4 py-1" style="width:100%;">
              <ContractItems
                class="ml-auto"
                :old-list="Form.details"
                :error-messages="templateDetailsErrors"
                :show-btn="true"
                @selectedList="getSelectedList"
              ></ContractItems>
            </div>
            <v-divider style="width:100%;"></v-divider>
            <v-list
              style="width:100%;flex:1;"
            >
              <draggable
                v-model="Form.details"
                style="height:100%"
                :group="groupSet"
                animation="300"
                @end="onEnd"
              >
                <v-list-item
                  v-for="(item, i) in Form.details"
                  :key="i"
                  style="height:14px; min-height:0;"
                  class="my-3"
                >
                  <v-list-item-action class="my-0 mr-0 py-0">
                    <v-chip
                      style="font-size:14px;height:20px; min-height:0;width: 100%;"
                      class="d-flex justify-space-between"
                      @click.stop
                    >
                      <v-icon
                        size="16"
                        color="red"
                        class="mx-1"
                        @click="outFrList(item)"
                      >
                        {{ icons.mdiCloseCircleOutline }}
                      </v-icon>
                      <span
                        style="font-size:14px; color:black;width: 1000px; overflow: hidden; text-overflow:ellipsis;"
                        class="px-5"
                        :title="item.ci_name"
                      >
                        {{ item.ci_name }}
                      </span>
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              </draggable>
            </v-list>
            <!-- <v-divider v-if="sumQuantity > 0" style="width:100%;"></v-divider>
            <div v-if="sumQuantity > 0" class="d-flex justify-space-between px-4 py-1" style="width:100%;">
              <span class="mr-8">
                契約科目数: {{ sumQuantity }}
              </span>
            </div> -->
          </v-sheet>
          <span v-if="detailsErrors" style="color:red;font-size:smaller;text-align:center">
            契約科目を設定して下さい !
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <label>テンプレートメモ</label>
        </v-col>
        <v-col
          cols="12"
          md="7"
        >
          <v-textarea
            v-model="$v.Form.tmpl_memo.$model"
            counter="100"
            rows="3"
            outlined
            dense
            placeholder="テンプレートメモ"
            :error-messages="memoErrors"
            hide-details="auto"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col
          offset-md="5"
          cols="12"
        >
          <v-btn
            color="primary"
            :loading="submitStatus"
            @click="submit(Form)"
          >
            Submit
          </v-btn>
          <v-btn
            class="mx-2"
            outlined
            @click="cancelClick"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import {
  mdiCameraOutline,
  mdiCloseCircleOutline,
} from '@mdi/js'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'

import {
  mapActions,
} from 'vuex'
import ContractItems from '@/components/ContractItems.vue'

export default {
  components: {
    ContractItems,
  },
  data: () => ({
    submitStatus: false,
    changeFlag: false,
    detailsErrors: false,
    icons: {
      mdiCameraOutline,
      mdiCloseCircleOutline,
    },
    Form: {
      tmpl_name: '',
      tmpl_memo: '',
      details: [],
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    groupSet: {
      name: 'Details',
      pull: false,
      put: false,
    },
  }),

  validations: {
    Form: {
      tmpl_name: {
        required,
        maxLength: maxLength(100),
      },
      tmpl_memo: {
        maxLength: maxLength(100),
      },
      details: {
        required,
      },
    },
  },
  computed: {
    // sumQuantity() {
    //   return this.Form.details.length
    // },

    nameErrors() {
      const errors = []
      if (!this.$v.Form.tmpl_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.tmpl_name.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.tmpl_name.maxLength && errors.push('100文字以内にして下さい。')

      return errors
    },
    memoErrors() {
      const errors = []
      if (!this.$v.Form.tmpl_memo.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.tmpl_memo.maxLength && errors.push('100文字以内にして下さい。')

      return errors
    },
    templateDetailsErrors() {
      const errors = []
      if (!this.$v.Form.details.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.Form.details.required && errors.push('必須項目')

      return errors
    },
  },
  watch: {
    Form: {
      handler() {
        this.changeFlag = true
      },
      deep: true,
    },
    'Form.details': {
      handler() {
        if (this.Form.details.length === 0) {
          this.detailsErrors = true
        } else {
          this.detailsErrors = false
        }
      },
    },
  },
  methods: {
    ...mapActions('templateStore', ['createTemplate']),
    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },
    getSelectedList(selectedList) {
      this.Form.details = selectedList.map((mal, i) => {
        const item = {
        }
        item.ci_id = mal.ci_id
        item.ci_name = mal.ci_name
        item.disp_order = i + 1

        return item
      })
    },
    outFrList(item) {
      const index = this.Form.details.findIndex(se => se.ci_id === item.ci_id)
      if (index >= 0) this.Form.details.splice(index, 1)
    },
    onEnd() {
      this.Form.details = this.Form.details.map((sd, i) => {
        const item = {
          ...sd,
        }
        item.disp_order = i + 1

        return item
      })
    },
    jumpToListPage() {
      this.$router.push({
        path: '/template-list',
      })
    },

    submit(Form) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.createTemplate(Form)
          .then(() => {
            this.submitStatus = false
            this.jumpToListPage()
          })
          .catch(error => {
            this.submitStatus = false
            console.log(error.response.data)
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.messages = error.response.data.message
            }
            console.log('apierror', this.apierror)
          })
      } else {
        if (this.Form.details.length === 0) {
          this.detailsErrors = true
        } else {
          this.detailsErrors = false
        }
        console.log('error submit!!')
      }
    },
  },
}
</script>
